import { template as template_af62edaa0b2944ada2c4b29d63dcab21 } from "@ember/template-compiler";
const FKLabel = template_af62edaa0b2944ada2c4b29d63dcab21(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
