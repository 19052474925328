import { template as template_7ac636de24c54a49962b5cc5c91a5cdc } from "@ember/template-compiler";
const FKText = template_7ac636de24c54a49962b5cc5c91a5cdc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
