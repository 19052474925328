import { template as template_fd6bc021f79846ccbeeb73dfea7e7b1a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_fd6bc021f79846ccbeeb73dfea7e7b1a(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
